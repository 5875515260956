import React, { useState } from "react";
import { ContactUsBg, ContactUsImg } from "../../utils/assetExports";

export default function ContactUs() {
  const bgList = [1, 2, 3, 4, 5, 6, 7, 8];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(`Data: ${name} ${email} ${message}`);
    // return;
    setLoading(true);
    try {
      // Perform API call here
      const response = await fetch(
        "https://api.dev.thegigsearch.com/api/web/contact_us/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: name, email: email, message: message }),
        }
      );
      console.log(`Response Data: ${JSON.stringify(response)}`);
      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      setSuccess(true);
      setName("");
      setEmail("");
      setMessage("");
      setError("");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      id="contact_us"
      className="relative bg-black rounded-[20px] overflow-hidden  "
    >
      <div className=" grid grid-cols-2 sm:grid-cols-4">
        {bgList.map((val) => (
          <img className="" key={val} src={ContactUsBg} alt="" />
        ))}
      </div>
      <div className="absolute inset-0 flex flex-col sm:flex-row items-center justify-evenly  ">
        <form
          className="flex flex-col gap-y-[25px] w-3/4 md:w-auto"
          onSubmit={handleSubmit}
        >
          <p className="text-white text-[32px] font-bold ">Contact us</p>
          <input
            type="text"
            className="outline-none border-b-2 border-[#D9D9D9] text-white sm:w-[380px] "
            style={{ backgroundColor: "transparent" }}
            placeholder="Name"
            required
            onChange={(val) => {
              const { name, value } = val.target;
              setName(value);
              console.log(`Name ${value}`);
            }}
            value={name}
          />
          <input
            type="Email"
            placeholder="Email"
            className="outline-none border-b-2 border-[#D9D9D9] text-white sm:w-[380px]"
            style={{ backgroundColor: "transparent" }}
            required
            onChange={(val) => {
              const { value } = val.target;
              setEmail(value);
            }}
            value={email}
          />

          <textarea
            placeholder="Type your message"
            className="outline-none border-b-2 border-[#D9D9D9] text-white sm:w-[380px]"
            style={{ resize: "none", backgroundColor: "transparent" }}
            rows={3}
            required
            onChange={(val) => {
              const { value } = val.target;
              setMessage(value);
            }}
            value={message}
          />
          {error && <p className="text-white">{error}</p>}
          {success && (
            <p className="text-white">Form submitted successfully!</p>
          )}

          <button
            type="submit"
            className="mt-4 py-2 w-[100px] bg-[#FFBF21] border-[#FFBF21] rounded-[20px] text-white font-bold"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
        <img className="p-[40px] sm:px-[0px]" src={ContactUsImg} />
      </div>
    </div>
  );
}
