import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Slider({ images }: { images: string[] }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // 3 seconds interval

    return () => clearInterval(interval); // Cleanup on unmount
  }, [images.length]);

  // Preload images to avoid lag during the transition
  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, [images]);

  return (
    <div className="absolute inset-0 z-0 overflow-hidden">
      <AnimatePresence initial={false} custom={currentImageIndex}>
        {images.map(
          (image, index) =>
            index === currentImageIndex && (
              <motion.div
                key={index}
                initial={{ x: "100%" }} // Start from right and faded out
                animate={{ x: 0, opacity: 1 }} // Slide to center and fade in
                exit={{ x: "-100%" }} // Slide to left and fade out
                transition={{
                  x: { type: "spring", stiffness: 50, damping: 57 }, // Smoother, with more damping
                  opacity: { duration: 12, ease: "easeInOut" }, // Smooth fade in/out
                }}
                className="absolute inset-0 w-full h-full"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            )
        )}
      </AnimatePresence>
    </div>
  );
}
