import { useState } from "react";
import { Mute, Unmute } from "../../utils/assetExports";

export default function StepThree() {
  const videoUrl = "https://www.thegigsearch.com/Tgs-video/TGS%20final.mp4";
  const [isMute, setIsMute] = useState(true);
  return (
    <div className="relative -z-0 rounded-[20px] overflow-hidden">
      <button onClick={() => setIsMute(!isMute)}>
        <video className="" autoPlay playsInline muted={isMute}>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </button>
      <button
        onClick={() => setIsMute(!isMute)}
        className="absolute top-4 right-4 z-20" // Position the button inside the video container
      >
        <img
          className="w-6 h-6 sm:w-12 sm:h-12" // Size the image appropriately
          src={isMute ? Mute : Unmute}
          alt="Mute-Unmute"
        />
      </button>
    </div>
  );
}
