import React, { useEffect, useRef, useState } from "react";
import {
  Feature01,
  Feature02,
  Feature03,
  Feature04,
} from "../../utils/assetExports";
import { motion, useInView } from "framer-motion";
import {
  NavigationActionTypes,
  useNavigationContext,
} from "../../context/navigationContext";

export default function Features() {
  const { state, dispatch } = useNavigationContext();

  const leftDivRef = useRef(null);
  const rightDivRef = useRef(null);
  const isLeftInView = useInView(leftDivRef, { once: false });
  const isRightInView = useInView(rightDivRef, { once: false });
  const [currIndex, setCurrIndex] = useState(0);
  var steps = [
    {
      title: "Login",
      subtitle: "Effortlessly log in using your trusted platform.",
    },
    {
      title: "Verify",
      subtitle: "Seamlessly verify your identity for a secure app experience.",
    },
    {
      title: "Apply",
      subtitle: "Effortlessly seek and apply for opportunities.",
    },
    {
      title: "Get Paid",
      subtitle: "Get paid promptly for your valuable contributions.",
    },
  ];
  var images = [Feature01, Feature02, Feature03, Feature04];
  return (
    <div className="flex flex-col sm:flex-row items-center sm:items-stretch justify-between sm:gap-x-[50px] gap-y-[20px]   ">
      <motion.div
        ref={leftDivRef}
        initial={
          !state.isMobile ? { x: -100, opacity: 0 } : { x: 0, opacity: 0 }
        }
        animate={isLeftInView ? { x: 0, opacity: 1 } : {}}
        transition={{ duration: 1 }}
        className="flex flex-col items-center justify-evenly overflow-hidden "
      >
        <h1 className="text-black font-bold text-[20px] sm:text-[32px]">
          Get Paid in Four Easy Steps
        </h1>
        <div className="grid grid-cols-2 gap-[20px] sm:gap-[80px] px-2">
          {steps.map((step, index) => (
            <div
              key={index}
              onMouseEnter={() => setCurrIndex(index)}
              //   className="hover:bg-orangeDark "
            >
              <p className="text-black font-bold text-[12px] sm:text-[16px]">
                Step 0{index + 1}
              </p>
              <h1 className="text-orange font-bold text-[20px] sm:text-[32px] drop-shadow-sm">
                {step.title}
              </h1>
              <p className="text-black text-[12px] sm:text-[16px]">
                {step.subtitle}
              </p>
            </div>
          ))}
        </div>
      </motion.div>
      <motion.div
        className="overflow-hidden"
        ref={rightDivRef}
        initial={
          !state.isMobile ? { x: 100, opacity: 0 } : { x: 0, opacity: 0 }
        }
        animate={isRightInView ? { x: 0, opacity: 1 } : {}}
        transition={{ duration: 1 }}
      >
        <img src={images[currIndex]} alt="" />
      </motion.div>
    </div>
  );
}
