import React from "react";
import { PrivacyBg } from "../utils/assetExports";

export default function PrivacyPolicyPage() {
  const tableOfContents = [
    "1. WHAT INFORMATION DO WE COLLECT?",
    "2. HOW DO WE PROCESS YOUR INFORMATION?",
    "3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?",
    "4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
    "5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
    "6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?",
    "7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?",
    "8. HOW LONG DO WE KEEP YOUR INFORMATION?",
    "9. HOW DO WE KEEP YOUR INFORMATION SAFE?",
    "10. DO WE COLLECT INFORMATION FROM MINORS?",
    "11. WHAT ARE YOUR PRIVACY RIGHTS?",
    "12. CONTROLS FOR DO-NOT-TRACK FEATURES",
    "13. DO WE MAKE UPDATES TO THIS NOTICE?",
    "14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
    `15. HOW CAN YOU REVIEW, UPDATE 
    OR DELETE THE DATA WE COLLECT FROM YOU?`,
  ];
  const section01Ul = [
    "names",
    "phone numbers",
    "email addresses",
    "mailing addresses",
    "job titles",
    "usernames",
    "passwords",
    "contact preferences",
    "contact or authentication data",
    "billing addresses",
    "debit/credit card numbers",
  ];
  const applicationDataPoints = [
    `Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.`,
    `Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's calendar, camera, and other features. If you wish to change our access or permissions, you may do so in your device's settings.`,
    `Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.`,
    `Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.`,
  ];
  const stepTwoPoints = [
    `To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
`,
    `To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
`,
    `To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
`,
    `To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
`,
    `To fulfil and manage your orders. We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.
`,
    `To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.
`,
    `To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
`,
    `To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
`,
    `To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.
`,
    `To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.`,
  ];
  const stepThreePoints = [
    `Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.
`,
    `Performance of a Contract. We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
`,
    `Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
`,
    `Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
`,
    `Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.`,
  ];
  const stepFourPoints = [
    `Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
`,
    `When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g. Google Maps API, Places API). We obtain and store on your device ('cache') your location for six (6) months. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
`,
    `Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
`,
    `Other Users. When you share personal information or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
`,
    `Offer Wall. Our application(s) may display a third-party hosted 'offer wall'. Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.`,
  ];
  const stepSixPoints = [
    `AI bots
`,
    `AI predictive analytics
`,
    `AI search
`,
    `AI insights
`,
    `Natural language processing
`,
    `Text analysis
`,
    `AI development`,
  ];
  function scrollToSection(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <div className="relative items-center flex flex-col  sm:mx-[160px] px-[20px]  bg-[#FFBF21] bg-opacity-20 rounded-[20px] overflow-hidden my-[50px] py-[30px] ">
      <img
        className="fixed -z-1 self-center w-[200px] h-[200px] text-center "
        src={PrivacyBg}
        alt="bg-image"
      />
      {/* heading */}
      <h1 className="text-orange-400 text-[30px] sm:text-[50px] font-bold self-center ">
        THE GIG SEARCH PRIVACY POLICY
      </h1>
      {/* intro */}
      <div className="flex flex-col gap-y-[10px] py-[10px]">
        <p className="text-black">Last updated August 14, 2024 </p>
        <p className="text-black">
          This privacy notice for A.R.M TECHNOLOGIES LIMITED (doing business as
          THE GIG SEARCH) ('we', 'us', or 'our'), describes how and why we might
          collect, store, use, and/or share ('process') your information when
          you use our services ('Services'), such as when you:
        </p>
        <ul className="pl-[40px] ">
          <li className="text-black list-disc ">
            Download and use our mobile application (THE GIG SEARCH), or any
            other application of ours that links to this privacy notice
          </li>
          <li className="text-black list-disc ">
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>

        <p className="text-black">
          <span className="font-bold">Questions or concerns? </span>Reading this
          privacy notice will help you understand your privacy rights and
          choices. If you do not agree with our policies and practices, please
          do not use our Services. If you still have any questions or concerns,
          please contact us at{" "}
          <a href="mailto:INFO@THEGIGSEARCH.COM">INFO@THEGIGSEARCH.COM</a> .
        </p>
      </div>
      {/* summary */}
      <div className="flex flex-col gap-y-[10px] py-[10px]">
        <h2 className="text-black font-bold text-[20px] pt-[20px]  ">
          SUMMARY OF KEY POINTS
        </h2>
        <p className="text-black font-bold ">
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>
        <p className="text-black">
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with us and the Services, the choices you make,
          and the products and features you use. Learn more about personal
          information you disclose to us. Do we process any sensitive personal
          information? We may process sensitive personal information when
          necessary with your consent or as otherwise permitted by applicable
          law. Learn more about sensitive information we process. Do we collect
          any information from third parties? We do not collect any information
          from third parties.How do we process your information? We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about how we process your
          information.In what situations and with which parties do we share
          personal information? We may share information in specific situations
          and with specific third parties. Learn more about when and with whom
          we share your personal information.How do we keep your information
          safe? We have organizational and technical processes and procedures in
          place to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about how we keep your
          information safe.What are your rights? Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more about
          your privacy rights.How do you exercise your rights? The easiest way
          to exercise your rights is by submitting a data subject access
          request, or by contacting us. We will consider and act upon any
          request in accordance with applicable data protection laws.Want to
          learn more about what we do with any information we collect? Review
          the privacy notice in full.
        </p>
      </div>
      {/* table of contents */}
      <div className="flex flex-col items-start gap-y-[10px] py-[4px] ">
        <h2 className="text-black font-bold text-[25px] ">TABLE OF CONTENTS</h2>
        <ol className="list-none">
          {tableOfContents.map((item, index) => {
            return (
              <li className="text-black font-bold text-[20px] py-[10px] self-start">
                <button onClick={() => scrollToSection(`section-${index + 1}`)}>
                  {item}
                </button>
              </li>
            );
          })}
        </ol>
      </div>
      <div
        id="section-1"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[0]}</h1>
        <div className="text-black flex flex-col gap-y-[10px] py-[10px] ">
          <p>
            Personal information you disclose to usIn Short: We collect personal
            information that you provide to us.We collect personal information
            that you voluntarily provide to us when you register on the
            Services, express an interest in obtaining information about us or
            our products and Services, when you participate in activities on the
            Services, or otherwise when you contact us.Personal Information
            Provided by You. The personal information that we collect depends on
            the context of your interactions with us and the Services, the
            choices you make, and the products and features you use. The
            personal information we collect may include the following:
          </p>
          <ul className="pl-[40px]">
            {section01Ul.map((item, index) => {
              return (
                <li key={item} className="text-black list-disc ">
                  {item}
                </li>
              );
            })}
          </ul>
          <p className="text-black">
            <span className="font-bold">Sensitive Information. </span> When
            necessary, with your consent or as otherwise permitted by applicable
            law, we process the following categories of sensitive information:
          </p>
          <ul className="pl-[40px] ">
            <li className="text-black list-disc ">• biometric data</li>
            <li className="text-black list-disc ">• student data</li>
          </ul>
          <p className="text-black">
            <span className="font-bold"> Payment Data.</span> We may collect
            data necessary to process your payment if you choose to make
            purchases, such as your payment instrument number, and the security
            code associated with your payment instrument. All payment data is
            handled and stored by GOCARDLESS. You may find their privacy notice
            link(s) here:{" "}
            <a
              className="text-blue-500"
              href="https://gocardless.com/privacy/merchants/"
              target="_blank"
            >
              https://gocardless.com/privacy/merchants/
            </a>
          </p>
          <p className="text-black">
            Payments for services, subscriptions, and penalties will be deducted
            from your respective accounts.
          </p>
          <p className="text-black">
            <span className="font-bold">Social Media Login Data.</span> We may
            provide you with the option to register with us using your existing
            social media account details, like your Facebook, X, or other social
            media account. If you choose to register in this way, we will
            collect certain profile information about you from the social media
            provider, as described in the section called 'HOW DO WE HANDLE YOUR
            SOCIAL LOGINS?' below.
          </p>
          <p>
            <span className="font-bold">Application Data.</span> If you use our
            application(s), we also may collect the following information if you
            choose to provide us with access or permission:
          </p>
          <ul className="pl-[40px] ">
            {applicationDataPoints.map((item, index) => (
              <li key={item} className="text-black list-disc ">
                {item}
              </li>
            ))}
          </ul>
          <p className="text-black">
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes. All personal information
            that you provide to us must be true, complete, and accurate, and you
            must notify us of any changes to such personal information.
          </p>
          <p className="text-black font-bold text-[20px]">Google API</p>
          <p className="text-black">
            Our use of information received from Google APIs will adhere to
            Google API Services User Data Policy, including the Limited Use
            requirements.
          </p>
        </div>
      </div>
      <div
        id="section-2"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[1]}</h1>
        <div className="text-black flex flex-col gap-y-[10px] py-[10px] ">
          <p className="text-black">
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </p>
          <p className="text-black font-bold">
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <ul className="pl-[40px] ">
            {stepTwoPoints.map((item, index) => (
              <li key={item} className="text-black list-disc ">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        id="section-3"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]"> {tableOfContents[2]}</h1>
        <div className="text-black flex flex-col gap-y-[10px] py-[10px] ">
          <p className="text-black">
            In Short: We only process your personal information when we believe
            it is necessary and we have a valid legal reason (i.e. legal basis)
            to do so under applicable law, like with your consent, to comply
            with laws, to provide you with services to enter into or fulfil our
            contractual obligations, to protect your rights, or to fulfil our
            legitimate business interests.
          </p>
          <p className="text-black">
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <ul className="pl-[40px] ">
            {stepThreePoints.map((item, index) =>
              index == 2 ? (
                <li key={item}>
                  {item}
                  <ul className="pl-[40px] list-disc py-[5px] ">
                    <li className="list-disc">
                      Analyse how our Services are used so we can improve them
                      to engage and retain users
                    </li>
                    <li className="list-disc">
                      Diagnose problems and/or prevent fraudulent activities
                    </li>
                    <li className="list-disc">
                      Understand how our users use our products and services so
                      we can improve user experience
                    </li>
                  </ul>
                </li>
              ) : (
                <li key={item} className="text-black list-disc ">
                  {item}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <div
        id="section-4"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[3]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> We may share information
          in specific situations described in this section and/or with the
          following third parties. We may need to share your personal
          information in the following situations:
        </p>
        <ul className="pl-[40px] ">
          {stepFourPoints.map((item, index) => {
            return (
              <li key={item} className="text-black list-disc ">
                {item}
              </li>
            );
          })}
        </ul>
      </div>
      <div
        id="section-5"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]"> {tableOfContents[4]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> In Short: We may use
          cookies and other tracking technologies to collect and store your
          information. We may use cookies and similar tracking technologies
          (like web beacons and pixels) to gather information when you interact
          with our Services. Some online tracking technologies help us maintain
          the security of our Services and your account, prevent crashes, fix
          bugs, save your preferences, and assist with basic site functions.
        </p>
        <p className="text-black">
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </p>
        <p className="text-black">
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </p>
      </div>
      <div
        id="section-6"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[5]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> We offer products,
          features, or tools powered by artificial intelligence, machine
          learning, or similar technologies.
        </p>
        <p className="text-black">
          As part of our Services, we offer products, features, or tools powered
          by artificial intelligence, machine learning, or similar technologies
          (collectively, 'AI Products'). These tools are designed to enhance
          your experience and provide you with innovative solutions. The terms
          in this privacy notice govern your use of the AI Products within our
          Services.Use of AI TechnologiesWe provide the AI Products through
          third-party service providers ('AI Service Providers'), including
          OpenAI. As outlined in this privacy notice, your input, output, and
          personal information will be shared with and processed by these AI
          Service Providers to enable your use of our AI Products for purposes
          outlined in 'WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
          INFORMATION?' You must not use the AI Products in any way that
          violates the terms or policies of any AI Service Provider.
        </p>
        <div>
          <h2 className="text-black font-bold ">Our AI Products</h2>
          <p className="text-black">
            Our AI Products are designed for the following functions:
          </p>
          <ul className="pl-[40px] ">
            {stepSixPoints.map((item, index) => {
              return (
                <li key={item} className="text-black list-disc ">
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <h2 className="text-black font-bold">
            How We Process Your Data Using AI
          </h2>
          <p className="text-black">
            All personal information processed using our AI Products is handled
            in line with our privacy notice and our agreement with third
            parties. This ensures high security and safeguards your personal
            information throughout the process, giving you peace of mind about
            your data's safety.
          </p>
        </div>
      </div>
      <div
        id="section-7"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]"> {tableOfContents[6]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> If you choose to register
          or log in to our Services using a social media account, we may have
          access to certain information about you.
        </p>
        <p className="text-black">
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or X
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned, but will often include your name, email address, friends
          list, and profile picture, as well as other information you choose to
          make public on such a social media platform.
        </p>
        <p className="text-black">
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
      </div>
      <div
        id="section-8"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]"> {tableOfContents[7]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> We keep your information
          for as long as necessary to fulfill the purposes outlined in this
          privacy notice unless otherwise required by law.
        </p>
        <p className="text-black">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than six
          (6) months past the start of the idle period of the user's account.
        </p>
        <p className="text-black">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </div>
      <div
        id="section-9"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]"> {tableOfContents[8]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> We aim to protect your
          personal information through a system of organisational and technical
          security measures.
        </p>
        <p className="text-black">
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
      </div>
      <div
        id="section-10"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]"> {tableOfContents[9]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> We do not knowingly
          collect data from or market to children under 18 years of age.
        </p>
        <p className="text-black">
          We do not knowingly collect, solicit data from, or market to children
          under 18 years of age, nor do we knowingly sell such personal
          information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at{" "}
          <a href="mailto:s.bokkalapally@thegigsearch.com">
            s.bokkalapally@thegigsearch.com
          </a>{" "}
          .
        </p>
      </div>
      <div
        id="section-11"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[10]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> In some regions, such as
          the European Economic Area (EEA), United Kingdom (UK), and
          Switzerland, you have rights that allow you greater access to and
          control over your personal information. You may review, change, or
          terminate your account at any time, depending on your country,
          province, or state of residence.
        </p>
        <p className="text-black">
          In some regions (like the EEA, UK, and Switzerland), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
          NOTICE?' below.
        </p>
        <p className="text-black">
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p className="text-black">
          {" "}
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your Member State data protection authority or UK data
          protection authority.
        </p>
        <p className="text-black">
          If you are located in Switzerland, you may contact the Federal Data
          Protection and Information Commissioner.
        </p>
        <p className="text-black">
          <span className="font-bold">Withdrawing your consent:</span> If we are
          relying on your consent to process your personal information, you have
          the right to withdraw your consent at any time. You can withdraw your
          consent at any time by contacting us by using the contact details
          provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
          below or updating your preferences.
        </p>
        <p className="text-black">
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </p>
        <p className="text-black">
          <span className="font-bold">
            Opting out of marketing and promotional communications:
          </span>{" "}
          You can unsubscribe from our marketing and promotional communications
          at any time by replying 'STOP' or 'UNSUBSCRIBE' to the SMS messages
          that we send, or by contacting us using the details provided in the
          section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will
          then be removed from the marketing lists. However, we may still
          communicate with you — for example, to send you service-related
          messages that are necessary for the administration and use of your
          account, to respond to service requests, or for other non-marketing
          purposes.
        </p>
        <p className="text-black font-bold">Account Information</p>
        <p className="text-black">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
          <ul className="pl-[40px] ">
            <li className="list-disc text-black">
              • Log in to your account settings and update your user account.
            </li>
            <li className="list-disc text-black">
              • Contact us using the contact information provided.
            </li>
          </ul>
        </p>
        <p className="text-black">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p className="text-black">
          <span className="font-bold">Cookies and similar technologies:</span>{" "}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. If you
          have questions or comments about your privacy rights, you may email us
          at <a href="mailto:INFO@THEGIGSEARCH.COM"> INFO@THEGIGSEARCH.COM</a>.
        </p>
      </div>
      <div
        id="section-12"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]"> {tableOfContents[11]}</h1>
        <p className="text-black">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ('DNT') feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognising and implementing DNT
          signals has been finalised. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
      </div>
      <div
        id="section-13"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[12]}</h1>
        <p className="text-black">
          <span className="font-bold">In Short:</span> Yes, we will update this
          notice as necessary to stay compliant with relevant laws.
        </p>
        <p className="text-black">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated 'Revised' date at the top of
          this privacy notice. If we make material changes to this privacy
          notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this privacy notice frequently to be informed of how we
          are protecting your information.
        </p>
      </div>
      <div
        id="section-14"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[13]}</h1>
        <p className="text-black">
          If you have questions or comments about this notice, you may contact
          our Data Protection Officer (DPO) by email at
          <a href="mailto:s.bokkalapally@thegigsearch.com">
            {" "}
            s.bokkalapally@thegigsearch.com
          </a>
          , by phone at 07307969911, or contact us by post at:
        </p>
        <div>
          <p>A.R.M TECHNOLOGIES LIMITED </p>
          <p>Data Protection Officer</p> <p>A.R.M Technologies limited</p>{" "}
          <p>Office 118, Regus, oxford point, 19 oxford road Bournemouth,</p>{" "}
          <p>Dorset BH88GS England</p>
        </div>
      </div>
      <div
        id="section-15"
        className="text-black flex flex-col gap-y-[10px] py-[10px] "
      >
        <h1 className="text-black text-[25px]">{tableOfContents[14]}</h1>
        <p className="text-black">
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          details about how we have processed it, correct inaccuracies, or
          delete your personal information. You may also have the right to
          withdraw your consent to our processing of your personal information.
          These rights may be limited in some circumstances by applicable law.
          To request to review, update, or delete your personal information,
          please fill out and submit a data subject access request.
        </p>
        <p className="text-black">
          By using the Gig Search app, you agree to the following terms and
          conditions regarding the management and deletion of your account. We
          prioritize your privacy and control over your personal data and
          provide a straightforward process for account deletion to ensure a
          transparent user experience.
        </p>
        <p className="text-black">
          If you have created an account on the Gig Search app, you have the
          right to delete your account at any time. The account deletion process
          is designed to be conducted directly within the app, allowing you to
          have full control over the personal information you have shared with
          us. To delete your account permanently please email the request to
          <a href="mailto:support@thegigsearch.com">
            {" "}
            support@thegigsearch.com
          </a>
          , You will be notified via confirmation email about the deletion.
        </p>
        <p className="text-black">
          For users who have connected their Gig Search account with third-party
          services or social networks, such as Facebook or LinkedIn, Gig Search
          provides the ability to revoke access and disconnect these services
          directly within the app. This measure ensures that no further data is
          shared between Gig Search and the connected service after account
          deletion. It is important to note that Gig Search does not store
          credentials or tokens for social networks off of your device. These
          credentials are solely used to facilitate direct connections between
          the app and the social network while the app is in use.
        </p>
        <p className="text-black">
          By deleting your account, you acknowledge that all personal data
          associated with your profile will be irreversibly removed, and you
          agree to release Gig Search from any responsibility for the recovery
          of this data once the deletion process is completed. If you have any
          questions or need further assistance regarding our account deletion
          process, you are encouraged to visit our{" "}
          <a
            className="text-orange"
            href="https://thegigsearch.com/faq"
            target="_blank"
          >
            Help Center
          </a>{" "}
          or contact our support team through{" "}
          <a href="mailto:support@thegigsearch.com">
            {" "}
            support@thegigsearch.com
          </a>
        </p>
        <p className="text-black">
          These terms and conditions are in place to ensure that your experience
          with Gig Search is secure and respects your privacy and choices. By
          using the Gig Search app, you accept these conditions and understand
          the implications of deleting your account.
        </p>
      </div>
    </div>
  );
}
