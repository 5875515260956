import React from "react";

export default function StepSix() {
  const featuresList = [
    "Unlike our competitors who primarily focus on Tier-1 cities, GIG Search is dedicated to connecting talent and opportunities in Tier-2 cities.",
    "We prioritize international students, offering tailored job opportunities and support to help them thrive.",
    "We offer a unique opportunity to enhance your skills across a variety of sectors, ensuring you stay ahead in your field and achieve your professional goals",
  ];
  return (
    <div>
      <h1 className="text-[20px] sm:text-[32px] font-bold text-center py-[20px] ">
        Why Choose Us
      </h1>
      <div className="space-y-[30px]">
        {featuresList.map((feature, index) => (
          <div className="flex flex-row justify-between h-[100px] border border-orange rounded-[20px] overflow-hidden">
            {(index + 1) % 2 !== 0 && (
              <div className="flex items-center justify-center bg-orange w-[100px] ">
                <p className="self-center text-white text-[20px] sm:text-[32px] font-bold">
                  {index + 1}
                </p>
              </div>
            )}
            <p className="self-center text-black text-[12px] px-2 font-bold md:text-[18px] md:px-2 text-left">
              {feature}
            </p>
            {(index + 1) % 2 === 0 && (
              <div className="flex  items-center justify-center  bg-orange  w-[100px] ">
                <p className="self-center text-white text-[32px] font-bold ">
                  {index + 1}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
