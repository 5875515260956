import React, { useEffect, useState } from "react";
import {
  ArrowDown,
  ArrowUp,
  MenuIcon,
  NavTgsIcon,
} from "../../utils/assetExports";
import { NavigationType, useLocation, useNavigate } from "react-router-dom";
import {
  NavigationActionTypes,
  useNavigationContext,
} from "../../context/navigationContext";

export default function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useNavigationContext();
  const [isOpen, setIsOpen] = useState(false);
  const tabs = [
    { title: "Home", hyperLink: "/" },
    // { title: "About", hyperLink: "" },
    { title: "Contact Us", hyperLink: "" },
  ];
  const handleScrollToContact = () => {
    const contactSection = document.getElementById("contact_us");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleOnNavTabPress = ({ index }: { index: number }) => {
    switch (index) {
      case 0:
        setIsOpen(false);
        navigate("/");
        break;
      case 1:
        setIsOpen(false);
        if (location.pathname === "/") {
          handleScrollToContact();
        } else {
          // setShouldScrollToContact(true);
          dispatch({
            type: NavigationActionTypes.SHOULD_SCROLL_TO_CONTACT,
            payload: true,
          });
          navigate("/");
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (state.shouldScrollToContact && location.pathname === "/") {
      handleScrollToContact();
      dispatch({
        type: NavigationActionTypes.SHOULD_SCROLL_TO_CONTACT,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, state.shouldScrollToContact]);
  return (
    <nav className="relative z-50 w-full flex flex-row items-center justify-between  bg-black px-[20px] py-3">
      <a href="/">
        <img className="w-[150px] sm:w-full " src={NavTgsIcon} alt="" />
      </a>
      <ul className="sm:flex sm:flex-row hidden sm:visible">
        {tabs.map((tab, index) => {
          return (
            <button
              onClick={() => handleOnNavTabPress({ index: index })}
              className="mx-4"
              key={index}
            >
              <li className="text-white text-[16px] font-bold ">{tab.title}</li>
            </button>
          );
        })}
      </ul>
      <div className="relative sm:hidden">
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="text-white"
        >
          <img className="h-[25px] w-[25px]" src={MenuIcon} alt="Menu Icon" />
        </button>
        {isOpen && (
          <div className="absolute z-20 w-[120px] bg-black flex flex-col items-start justify-center p-[10px] border rounded-sm -right-[0px] sm:hidden gap-y-[10px] ">
            {tabs.map((tab, index) => (
              <button
                className="text-white font-bold "
                onClick={() => handleOnNavTabPress({ index: index })}
              >
                {tab.title}
              </button>
            ))}
          </div>
        )}
      </div>
      {/* <CustomButton
        title="Get Started"
        onClick={() => {}}
        buttonType={ButtonType.PrimaryShort}
      /> */}
    </nav>
  );
}
