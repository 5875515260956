import { useEffect } from "react";
import "./App.css";
import ContactUs from "./components/molecules/ContactUs";
import Features from "./components/molecules/Features";
import HeroImgComponent from "./components/molecules/HeroImgComponent";
import StepFive from "./components/molecules/StepFive";
import StepFour from "./components/molecules/StepFour";
import StepOne from "./components/molecules/StepOne";
import StepSeven from "./components/molecules/StepSeven";
import StepSix from "./components/molecules/StepSix";
import StepThree from "./components/molecules/StepThree";
import StepTwo from "./components/molecules/StepTwo";
import {
  useNavigationContext,
  NavigationActionTypes,
} from "./context/navigationContext";

function App() {
  const { state, dispatch } = useNavigationContext();
  useEffect(() => {
    const handleResize = () => {
      dispatch({
        type: NavigationActionTypes.IS_MOBILE,
        payload: window.innerWidth <= 800,
      });
    };

    window.addEventListener("resize", handleResize);

    // Check the screen size on component mount
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="App">
      <header className="App-header"></header>

      <HeroImgComponent />
      <main className="mx-[10px] sm:mx-[150px] flex flex-col items-center space-y-[50px] my-[50px]">
        <StepOne />
        <StepTwo />
        <StepThree />
        <StepFour />
        <StepFive />
        <StepSix />
        <Features />
        <StepSeven />
        <ContactUs />
      </main>
    </div>
  );
}

export default App;
