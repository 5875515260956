import React, { useEffect, useRef, useState } from "react";
import {
  StepOneGrid01,
  StepOneGrid02,
  StepOneGrid03,
  StepOneGrid04,
  StepOneGrid05,
  StepOneGrid06,
} from "../../utils/assetExports";
import { motion, useInView } from "framer-motion";
import { useNavigationContext } from "../../context/navigationContext";

export default function StepOne() {
  const { state } = useNavigationContext();
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });
  const gridItems = [
    { img: StepOneGrid01, subtitle: "University Timings" },
    { img: StepOneGrid02, subtitle: "Lack of Experience" },
    { img: StepOneGrid03, subtitle: "Limited Job Opportunities" },
  ];
  return (
    <div className="flex flex-col items-center ">
      <div className="flex w-full h-40 items-center justify-center">
        <p className="text-[18px] md:text-[32px] md:font-semibold text-center font-bold">
          Struggling to find Part time job as an international Student
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-[50px] gap-y-[8px] sm:gap-x-[200px] sm:gap-y-[80px] pt-[40px] p-4">
        {gridItems.map((gridItem, index) => (
          <div key={index} className="items-center">
            <motion.img
              className="self-center "
              ref={ref}
              initial={
                state.isMobile ? { y: 20, opacity: 1 } : { y: 100, opacity: 0 }
              }
              animate={
                inView
                  ? {
                      y: 0,
                      opacity: 1,
                      transition: { type: "spring", stiffness: 800 },
                    }
                  : {}
              }
              exit={{ y: 100, opacity: 0 }}
              src={gridItem.img}
            />
            <p className="text-center text-sm font-semibold mt-4">
              {gridItem.subtitle}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
