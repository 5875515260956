import { createContext, ReactNode, useContext, useReducer } from "react";

export interface NavigationStateType {
  shouldScrollToContact: boolean;
  isMobile: boolean;
}

const initialState = {
  shouldScrollToContact: false,
  isMobile: false,
} as NavigationStateType;

export const NavigationActionTypes = {
  SHOULD_SCROLL_TO_CONTACT: "SHOULD_SCROLL_TO_CONTACT",
  IS_MOBILE: "IS_MOBILE",
};

const navigationContext = createContext<{
  state: NavigationStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => {},
});

interface NavigationAction {
  type: string;
  payload: any;
}

const NavigationReducer = (
  state: NavigationStateType,
  action: NavigationAction
) => {
  const payload = action.payload;
  switch (action.type) {
    case NavigationActionTypes.SHOULD_SCROLL_TO_CONTACT:
      return {
        ...state,
        shouldScrollToContact: payload,
      };
    case NavigationActionTypes.IS_MOBILE:
      return {
        ...state,
        isMobile: payload,
      };

    default:
      console.log(
        `createNewJobReducer reducer was called with: ${action.type}, payload ${payload}`
      );
      throw new Error(); // this should throw some info
  }
};

const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<any>(NavigationReducer, initialState);
  const value: any = { state, dispatch };
  return (
    <navigationContext.Provider value={value}>
      {children}
    </navigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  return useContext(navigationContext);
};

export default NavigationProvider;
