import { StepSevenBg } from "../../utils/assetExports";

export default function StepSeven() {
  return (
    <div className="relative flex flex-col items-center justify-center rounded-[20px] overflow-hidden">
      <img
        className="h-auto rounded-[20px]"
        src={StepSevenBg}
        alt=""
        width={1280}
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center">
        <h1 className="text-white text-[20px] sm:text-[32px] sm:px-[100px] font-bold drop-shadow-xl">
          Become a Part of the GIG Community and Advance Your Career to New
          Heights
        </h1>
        {/* <button className="mt-4 py-2 w-[150px] sm:w-[200px] bg-[#FFBF21] border-[#FFBF21] text-[12px] sm:text-[16px] rounded-[20px] text-white font-bold">
          Join the Community
        </button> */}
      </div>
    </div>
  );
}
