import React, { useState } from "react";
import { ArrowDown, ArrowUp } from "../utils/assetExports";
import { useNavigate } from "react-router-dom";
import {
  useNavigationContext,
  NavigationActionTypes,
} from "../context/navigationContext";

export default function FaqPage() {
  const navigate = useNavigate();
  const { state, dispatch } = useNavigationContext();
  const faqsList = [
    {
      question: "What is The Gig Search?",
      answer:
        "The Gig Search is a digital staffing solution designed to connect businesses with a flexible, pre-vetted workforce. Our platform makes it easy for companies to meet their ad-hoc staffing needs by offering a seamless way to find and hire talent on-demand. Whether you need temporary staff for short-term projects or to fill immediate gaps, The Gig Search streamlines the hiring process, saving you time and resources. Powered by advanced technology and a user-friendly interface, we provide a reliable, efficient solution for your staffing requirements.",
    },
    {
      question: "I have an independent business, can I use TGS?",
      answer:
        "Absolutely! The Gig Search is designed to support businesses of all sizes, including independent businesses. Whether you need temporary staff for a short-term project, seasonal help, or specialized skills, our platform connects you with pre-vetted talent to meet your specific needs. With The Gig Search, you can easily find and hire the right people to help your business thrive.",
    },
    {
      question: "What are the advantages of using TGS?",
      answer:
        "The Gig Search offers a range of advantages for your hiring needs. With access to a pool of pre-vetted talent, our platform saves you time and reduces recruitment costs. Whether you need temporary, part-time, or project-based staff, TGS provides flexible and cost-effective staffing solutions. Our user-friendly interface and AI-driven matching technology ensure you find the best candidates for your business quickly and efficiently. Plus, TGS is perfect for businesses of all sizes, offering scalable support to help you grow.",
    },
    {
      question: "How does TGS ensure compliance with right to work laws?",
      answer:
        "The Gig Search ensures compliance with right-to-work laws by thoroughly verifying the eligibility of all candidates before they are listed on our platform. We conduct comprehensive checks on documentation and work authorization to ensure that all candidates meet the legal requirements for employment. Our platform is designed to keep you informed and compliant, providing peace of mind that your hiring processes align with current legal standards.",
    },
    {
      question: "What sectors are TGS available in?",
      answer:
        "TGS focuses mainly on the hospitality sector and the service industry sectors subject to availability of temporary jobs. ",
    },
    { question: "What is TGS’ cancellation policy?", answer: "" },
  ];
  const [faqOpenStatus, setFaqOpenStatus] = useState<boolean[]>(
    new Array(faqsList.length).fill(false)
  );
  return (
    <div className="relative items-center flex flex-col my-[20px]  sm:mx-[160px] px-[20px]">
      <div>
        <h1 className="text-black font-bold text-[34px]  self-center text-center ">
          Frequently Asked Questions
        </h1>
        <p className="font-medium text-[20px]">
          Welcome to The Gig Search FAQ! Here, you'll find quick answers to
          common questions about our platform, how it works, and how it benefits
          both gig workers and employers. Whether you're seeking flexible work,
          looking to hire, or curious about our mission, explore our FAQ to
          learn more.
        </p>
      </div>
      <div className="w-full">
        {faqsList.map((item, index) => {
          return (
            <div className="w-full ">
              <button
                onClick={() => {
                  const updatedStatus = [...faqOpenStatus];

                  updatedStatus[index] = !updatedStatus[index];
                  setFaqOpenStatus(updatedStatus);
                }}
                className="w-full"
              >
                <div className="flex flex-row justify-start h-[100px]  border border-orange rounded-[20px] my-[20px] overflow-hidden  ">
                  <div className="self-start flex items-center justify-center bg-orange w-[100px] h-full">
                    <p className="self-center text-white text-[20px] sm:text-[32px] font-bold">
                      {index + 1}
                    </p>
                  </div>
                  <div className="flex flex-1 justify-between items-center px-10 ">
                    <p className="self-center text-black text-[12px] font-bold sm:text-[18px]">
                      {item.question}
                    </p>
                    <img
                      className="h-[32px] "
                      src={faqOpenStatus[index] ? ArrowUp : ArrowDown}
                      alt="arrow"
                    />
                  </div>
                </div>
              </button>

              {faqOpenStatus[index] && (
                <div className="border border-orange rounded-[20px] items-center justify-center ">
                  {index !== 5 ? (
                    <p className="text-[16px] font-semibold  p-[20px]">
                      {item.answer}
                    </p>
                  ) : (
                    <div className="p-[20px]">
                      <p className="text-[20px] font-semibold">
                        Employer Cancellation Policy
                      </p>
                      <ol className="list-decimal pl-5">
                        <li className="text-[16px] font-semibold">
                          Cancellation Within 2 Hours of Posting:
                          <ul className="list-disc pl-5">
                            <li className="text-[16px] font-semibold">
                              Conditions: Employers can cancel a job or shift
                              request within 2 hours of posting it, provided
                              that the job or shift start time is more than 24
                              hours away.
                            </li>
                            <li className="text-[16px] font-semibold">
                              Fees: There are no fees for cancellations made
                              under these conditions.
                            </li>
                          </ul>
                        </li>
                        <li className="text-[16px] font-semibold">
                          Cancellation on the Day of Work (16 Hours or Less
                          Before Start Time):
                          <ul className="list-disc pl-5">
                            <li className="text-[16px] font-semibold">
                              Conditions: If an employer cancels a job or shift
                              request on the day of the work and 16 hours or
                              less before the start time, the following applies:
                              <ul className="list-disc pl-5">
                                <li className="text-[16px] font-semibold">
                                  Premium Plan: No cancellation fees.
                                </li>
                                <li className="text-[16px] font-semibold">
                                  Basic Plan: The employer is required to pay
                                  the commission or operational costs associated
                                  with the shift.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="text-[16px] font-semibold">
                          Cancellation Less Than 2 Hours Before Start Time:
                          <ul className="list-disc pl-5  ">
                            <li className="text-[16px] font-semibold">
                              Conditions: If an employer cancels a job or shift
                              request less than 2 hours before the start time,
                              the following fees apply:
                              <ul className="list-disc pl-5">
                                <li className="text-[16px] font-semibold">
                                  Basic Plan: The employer must pay the full
                                  commission and 50% of the pay for the shift.
                                </li>
                                <li className="text-[16px] font-semibold">
                                  Premium Plan:The employer must pay the full
                                  commission and 25% of the pay for the shift.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="w-full flex flex-col items-center ">
        <h1 className="text-black font-bold text-[34px] ">
          We are here to Help!
        </h1>
        <p className="font-medium text-[20px]">
          If you have more questions or need further assistance, simply click
          the Help button below and fill out the form.
        </p>
        <button
          onClick={() => {
            dispatch({
              type: NavigationActionTypes.SHOULD_SCROLL_TO_CONTACT,
              payload: true,
            });
            navigate("/");
          }}
          className="bg-orange text-white text-[20px] font-bold px-[100px] py-[10px] border rounded-3xl "
        >
          Help
        </button>
      </div>
    </div>
  );
}
