import React from "react";
import {
  StepFourGrid01,
  StepFourGrid02,
  StepFourGrid03,
  StepFourGrid04,
  StepFourGrid05,
  StepFourGrid06,
} from "../../utils/assetExports";

export default function StepFour() {
  const gridItems = [
    {
      img: StepFourGrid01,
      title: "Extensive Talent Database",
      subtitle: "Access a wide range of skilled and unskilled workers",
      cta: "learn more",
    },
    {
      img: StepFourGrid02,
      title: "AI - Powered Matchmaking",
      subtitle: "Advanced algorithms for accurate job matching",
      cta: "learn more",
    },
    {
      img: StepFourGrid03,
      title: "Comprehensive career support",
      subtitle: "Resources for skill enhancement and career growth.",
      cta: "learn more",
    },
    {
      img: StepFourGrid04,
      title: "Diverse Job Categories",
      subtitle:
        "A broad selection of job types for various needs in real time.",
      cta: "learn more",
    },
    {
      img: StepFourGrid05,
      title: "Commitment to Empowerment",
      subtitle: "Providing opportunities for diverse backgrounds.",
      cta: "learn more",
    },
    {
      img: StepFourGrid06,
      title: "Effective Talent Management",
      subtitle: "Efficient and streamlined hiring processes.",
      cta: "learn more",
    },
  ];
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-center sm:px-[200px] text-[18px] sm:text-[32px] font-bold">
        Experience a Full Suite of Features with The GIG Search
      </h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-[10px] sm:gap-y-[80px] pt-[40px] ">
        {gridItems.map((gridItem, index) => (
          <div key={index} className="flex flex-row items-start">
            <img src={gridItem.img} />
            <div className="space-y-[2px] sm:space-y-[6px] pl-[5px] sm:pl-[20px]">
              <p className="text-[14px] sm:text-[20px] font-bold">
                {gridItem.title}
              </p>
              <p className="text-[12px] sm:text-[16px]">{gridItem.subtitle}</p>
              {/* <button className="text-[#FF4800] text-[12px] sm:text-[16px] sm:text-bold ">
                {gridItem.cta}
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
