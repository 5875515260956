import React, { useEffect, useState } from "react";
import { FooterTgsIcon } from "../../utils/assetExports";

export default function Footer() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640); // 640px is the sm breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    // Check the screen size on component mount
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const buttonsList = [
    // { title: "About", hyperLink: "" },
    { title: "Terms and Conditions" },
    { title: "FAQ's", hyperLink: "/faq" },
    { title: "Privacy Policy", hyperLink: "/privacypolicy" },
  ];
  const socialMediaLinks = [
    {
      title: "LinkedIn",
      hyperLink: "https://uk.linkedin.com/company/thegigsearch",
    },
    { title: "Facebook" },
    { title: "Twitter" },
    { title: "Youtube" },
    {
      title: "Instagram",
      hyperLink:
        "https://www.instagram.com/thegigsearch.co.uk/?igsh=MTRoejdtaW5ycG5hMA%3D%3D",
    },
  ];
  return (
    <footer className="bg-black mx-[10px] sm:mx-[150px] p-[30px] rounded-[20px] mb-10">
      <div className="flex flex-col sm:flex-row justify-between pb-[20px]">
        {!isMobile && (
          <div>
            <a href="/">
              <img src={FooterTgsIcon} alt="" />
            </a>

            <div className="py-4">
              <p className="text-[#ddd] text-sm">118, Regus, Oxford Point,</p>
              <p className="text-[#ddd] text-sm">
                {" "}
                Bournemouth, England, BH88GS
              </p>
            </div>
          </div>
        )}
        <div className="flex flex-row justify-between gap-x-8 ">
          <div>
            <p className="text-white text-start py-[2px] font-semibold">
              Company
            </p>
            <ul className="flex flex-col items-start  ">
              {buttonsList.map((buttonName, index) => (
                <li key={buttonName.title}>
                  <a
                    href={buttonName.hyperLink}
                    className="text-[#ddd] text-start py-[2px] text-sm"
                  >
                    {buttonName.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="text-white text-start py-[2px] font-semibold">
              Social
            </p>
            <ul className="flex flex-col items-start w-[100px] ">
              {socialMediaLinks.map((socialMediaLink, index) => (
                <li>
                  <a
                    href={socialMediaLink.hyperLink}
                    target="_blank"
                    key={socialMediaLink.title}
                    className="text-[#ddd] text-start py-[2px] text-sm"
                    rel="noreferrer"
                  >
                    {socialMediaLink.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isMobile && (
          <div>
            <a href="/">
              <img src={FooterTgsIcon} alt="" />
            </a>

            <div className="py-[10px]">
              <p className="text-white">118, Regus, Oxford Point,</p>
              <p className="text-white"> Bournemouth, England, BH88GS</p>
            </div>
          </div>
        )}
      </div>
      <div className="w-[100%] h-[1px] bg-white" />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between pt-[30px]">
        <p className="text-[#ddd] text-sm">
          Copyrights © 2024 The GIG Search. All rights reserved
        </p>
        <p className="text-[#ddd] text-sm pr-1">London, UK</p>
      </div>
    </footer>
  );
}
