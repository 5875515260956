import { Appstore, MobileAppImage, Playstore } from "../../utils/assetExports";

export default function StepFive() {
  return (
    <div className="space-y-[50px]">
      <div className="flex flex-col  sm:flex-row rounded-[20px] overflow-hidden ">
        <div>
          <img
            className="object-cover"
            src={MobileAppImage}
            alt=""
            width={620}
          />
        </div>
        <div className="flex flex-col justify-evenly sm:pr-[100px] bg-[#FFBF21] p-4 md:p-6">
          <p className="font-bold text-white ">Find Talent</p>
          <div>
            <p className="text-orangeDark font-bold text-[20px] sm:text-[32px]">
              Stop Missing Out:
            </p>
            <p className="text-white font-bold text-[20px] sm:text-[32px]">
              Slash Your Tax and Training Costs Today!
            </p>
          </div>
          <p className="text-white text-[12px] sm:text-[16px] ">
            Download the GIG Search Mobile app now to find a Part time Job that
            fits you.
          </p>
          {/* <button className="py-2 w-[70px] sm:w-[100px] text-[12px] sm:text-[16px] bg-orangeDark border-orangeDark rounded-[20px] text-white font-bold">
            Hi
          </button> */}
          <div className="flex flex-row gap-4 pt-4 ">
            <a href="https://play.google.com/store/apps/details?id=com.thegigapp">
              <img src={Playstore} className="shadow-md hover:cursor-pointer" />
            </a>
            <a href="https://apps.apple.com/gb/app/the-gig-search/id6636478643">
              <img src={Appstore} className="shadow-md hover:cursor-pointer" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-y-[20px] bg-orangeDark h-[200px] rounded-[20px] overflow-hidden px-4">
        <h1 className="text-white text-[20px] sm:text-[32px] font-bold ">
          Find talent that will fill the gap and enhance your business
        </h1>
        {/* <button className="py-2 w-[100px] sm:w-[200px] text-[12px] sm:text-[16px] bg-[#FFBF21] border-[#FFBF21] rounded-[20px] text-white font-bold">
          Learn More
        </button> */}
      </div>
    </div>
  );
}
