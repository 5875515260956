const HeroImage = "/assets/HeroImage.svg";
const NavTgsIcon = "/assets/NavTgsIcon.svg";
const FooterTgsIcon = "/assets/FooterTgsIcon.svg";
const StepOneGrid01 = "/assets/StepOneGrid01.svg";
const StepOneGrid02 = "/assets/StepOneGrid02.svg";
const StepOneGrid03 = "/assets/StepOneGrid03.svg";
const StepOneGrid04 = "/assets/StepOneGrid04.svg";
const StepOneGrid05 = "/assets/StepOneGrid05.svg";
const StepOneGrid06 = "/assets/StepOneGrid06.svg";
const MobileAppImage = "/assets/MobileAppImage.jpg";
const Playstore = "/assets/Playstore.svg";
const Appstore = "/assets/Appstore.svg";
const VideoImg = "/assets/VideoImg.svg";
const StepFourGrid01 = "/assets/StepFourGrid01.svg";
const StepFourGrid02 = "/assets/StepFourGrid02.svg";
const StepFourGrid03 = "/assets/StepFourGrid03.svg";
const StepFourGrid04 = "/assets/StepFourGrid04.svg";
const StepFourGrid05 = "/assets/StepFourGrid05.svg";
const StepFourGrid06 = "/assets/StepFourGrid06.svg";
const StepSevenBg = "/assets/StepSevenBg.jpg";
const ContactUsImg = "/assets/ContactUsImg.svg";
const ContactUsBg = "/assets/ContactUsBg.svg";
const Feature01 = "/assets/Feature01.svg";
const Feature02 = "/assets/Feature02.svg";
const Feature03 = "/assets/Feature03.svg";
const Feature04 = "/assets/Feature04.svg";

const HeroImage01 = "/assets/HeroImage01.jpg";
const HeroImage02 = "/assets/HeroImage02.jpg";
const HeroImage03 = "/assets/HeroImage03.jpg";
const HeroImage04 = "/assets/HeroImage04.jpg";
const HeroImage05 = "/assets/HeroImage05.jpg";
const HeroImage06 = "/assets/HeroImage06.jpg";
const HeroImage07 = "/assets/HeroImage07.jpg";
const HeroImage08 = "/assets/HeroImage08.jpg";
const HeroImage09 = "/assets/HeroImage09.jpg";
const HeroImage10 = "/assets/HeroImage10.jpg";
const HeroImage11 = "/assets/HeroImage11.jpg";
const HeroImage12 = "/assets/HeroImage12.jpg";
const HeroImage13 = "/assets/HeroImage13.jpg";
const HeroImage15 = "/assets/HeroImage15.jpg";
const PrivacyBg = "/assets/PrivacyBg.svg";
const ArrowUp = "/assets/ArrowUp.svg";
const ArrowDown = "/assets/ArrowDown.svg";
const MenuIcon = "/assets/MenuIcon.svg";
const Mute = "/assets/Mute.svg";
const Unmute = "/assets/Unmute.svg";

export {
  Mute,
  Unmute,
  ArrowUp,
  ArrowDown,
  MenuIcon,
  HeroImage,
  NavTgsIcon,
  FooterTgsIcon,
  StepOneGrid01,
  StepOneGrid02,
  StepOneGrid03,
  StepOneGrid04,
  StepOneGrid05,
  StepOneGrid06,
  MobileAppImage,
  Playstore,
  Appstore,
  VideoImg,
  StepFourGrid01,
  StepFourGrid02,
  StepFourGrid03,
  StepFourGrid04,
  StepFourGrid05,
  StepFourGrid06,
  StepSevenBg,
  ContactUsImg,
  ContactUsBg,
  Feature01,
  Feature02,
  Feature03,
  Feature04,
  HeroImage01,
  HeroImage02,
  HeroImage03,
  HeroImage04,
  HeroImage05,
  HeroImage06,
  HeroImage07,
  HeroImage08,
  HeroImage09,
  HeroImage10,
  HeroImage11,
  HeroImage12,
  HeroImage13,
  PrivacyBg,
  HeroImage15,
};
